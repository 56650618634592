// Imports
import Flickity from 'flickity';
import imagesLoaded from 'imagesloaded';

// Define common carousel options
const commonOptions = {
    cellAlign: 'left',
    cellSelector: '.carousel-cell',
    pageDots: false,
    prevNextButtons: false,
    setGallerySize: false,
    wrapAround: false,
};

class Carousels {
    constructor() {}

    init() {
        let carouselOptions = {
            'general': {
                ...commonOptions,
            },
        };

        const carouselEls = document.querySelectorAll('.carousel-wrapper');
        carouselEls.forEach((el) => {
            let carousel = el.querySelector('.carousel');
            const carouselLoops = el.classList.contains('_carousel-loop');

            let imgsLoad = imagesLoaded(carousel);

            let carouselOptionsSettings = 'general';

            if (carouselLoops) {
                // Update wrapAround value if the _carousel-loop class is present
                carouselOptions[carouselOptionsSettings].wrapAround = true;
            }

            function manageButtonState(flkty, prevButton, nextButton) {
                if (!carouselLoops) {
                    // Set button states
                    if (flkty.selectedIndex === 0) {
                        prevButton.disabled = true; // Disable previous button
                        nextButton.disabled = false; // Enable next button
                    } else if (flkty.selectedIndex === flkty.slides.length - 1) {
                        prevButton.disabled = false; // Enable previous button
                        nextButton.disabled = true; // Disable next button
                    } else {
                        prevButton.disabled = false; // Enable previous button
                        nextButton.disabled = false; // Enable next button
                    }
                }
            }

            // function onAlways(instance) {
                // Set flickity carousel
                let flkty = new Flickity(carousel, carouselOptions[carouselOptionsSettings]);

                // Elements
                let arrows = el.querySelector('.carousel-arrows');

                // If home slider, find arrows within DOM instead
                if (el.classList.contains('_home-carousel')) {
                    arrows = document.querySelector('.carousel-arrows');
                }

                if (arrows) {
                    // Previous button
                    let prevButton = arrows.querySelector('.carousel-arrow-prev');
                    // Next button
                    let nextButton = arrows.querySelector('.carousel-arrow-next');

                    manageButtonState(flkty, prevButton, nextButton);

                    // Update button states on select
                    flkty.on('change', function() {
                        manageButtonState(flkty, prevButton, nextButton);
                    });

                    // Prev button click
                    prevButton.addEventListener('click', function () {
                        flkty.previous();
                    });

                    // Next button click
                    nextButton.addEventListener('click', function () {
                        flkty.next();
                    });
                }
            // }

            // All images have loaded, no matter of their state
            //imgsLoad.on('always', onAlways);
        });
    }
}

export default new Carousels();
