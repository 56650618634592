/* eslint-disable */
const PASSIVE_SUPPORTED = (() => {
	let supported = false;
	try {
		window.addEventListener(
			'test',
			null,
			Object.defineProperty({}, 'passive', {
				get: function() {
					supported = true;
				},
			})
		);
	} catch (err) {}

	return supported;
})();

const addSlashes = string => {
	string = string.substr(-1) === '/' ? string : string + '/';
	return (string = string.substr(0, 1) === '/' ? string : '/' + string);
};

const testHrefIsActual = href => {
	if (href === '' || href === '#' || !href) {
		return false;
	}
	href = addSlashes(href);

	return (
		href === addSlashes(document.location.href.replace(document.location.origin, '')) ||
		href === addSlashes(document.location.href) ||
        href === document.location.href
	);
};
/* eslint-enable */

const preloadImages = (images) => {
    for (let i = 0; i < images.length; ++i) {
        let img = new Image();
        img.src = images[i];
    }
}

const preloadImagesSequential = (images, index) => {
    index = index || 0;

    if (images && images.length > index) {
        let img = new Image ();

        img.onload = () => {
            preloadImages(images, index + 1);
        }
        img.src = images[index];
    }
};

const detectConnectionType = () => {
    console.log(navigator.connection);
    if (navigator.connection) {
        if (navigator.connection.type || navigator.connection.effectiveType) {
            const connectionType = navigator.connection.type;
            const connectionEffectiveType = navigator.connection.effectiveType;
            console.log('Connection type:', connectionType);
            console.log('Connection effectiveType:', connectionEffectiveType);

            if (connectionType === 'cellular' || connectionEffectiveType !== '4g') {
                console.log('Connection speed: Slow');
                return 'slow';
            } else {
                console.log('Connection speed: Fast');
                return 'fast';
            }
        }
    } else {
        console.log('navigator.connection API not supported in this browser.');
        return 'not supported';
    }
};

const detectPerformanceBots = () => {
    if (navigator.userAgent.indexOf("Chrome-Lighthouse") > -1) {
        return true;
    } else {
        return false;
    }
};

// Function to check if an element is % in the viewport
// 0.1 = 10%
const isElementInViewport = (el, amount = 0.1) => {
	const rect = el.getBoundingClientRect();
	const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
	return (rect.top + rect.height * amount) < windowHeight;
};

const utils = {
	now() {
		if (window.performance && window.performance.now) {
			this.now = () => {
				return window.performance.now();
			};
		} else {
			this.now = () => {
				return +new Date();
			};
		}
		return this.now();
	},

	cubicProgress(value) {
		value = value < 0 ? 0 : value;
		value = value > 1 ? 1 : value;
		value /= 1 / 2;

		if (value < 1) {
			return (1 / 2) * value * value * value;
		}

		value -= 2;

		return (1 / 2) * (value * value * value + 2);
	},

	debounce(func, wait = 100, immediate = false) {
		let timeout;
		return function() {
			const context = this;
			const args = arguments;

			const later = () => {
				timeout = null;
				!immediate && func.apply(context, args);
			};

			const callNow = immediate && !timeout;
			clearTimeout(timeout);

			timeout = setTimeout(later, wait);

			callNow && func.apply(context, args);
		};
	},

	throttle(func, wait = 100) {
		let isThrottled = false;
		let savedArgs;
		let savedThis;

		function wrapper() {
			if (isThrottled) {
				savedArgs = arguments;
				savedThis = this;
			} else {
				func.apply(this, arguments);

				isThrottled = true;

				setTimeout(() => {
					isThrottled = false;
					if (savedArgs) {
						wrapper.apply(savedThis, savedArgs);
						savedArgs = savedThis = null;
					}
				}, wait);
			}
		}

		return wrapper;
	},

	formatNumber(number) {
		number = number.toString();
		let result = '';

		for (let k = number.length - 1, c = 0; k >= 0; k--, c++) {
			if (c === 3) {
				c = 0;
				result = number.substr(k, 1) + ' ' + result;
			} else {
				result = number.substr(k, 1) + result;
			}
		}

		return result;
	},

	/*declOfNum(number, titles) {
		const cases = [2, 0, 1, 1, 1, 2];
		return titles[
			number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
		];
	},*/

	/*testHitViewport(el) {
		const rect = el.getBoundingClientRect();
		// DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
		const windowHeight = window.innerHeight || document.documentElement.clientHeight;
		const windowWidth = window.innerWidth || document.documentElement.clientWidth;
		// http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
		const vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0;
		const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;
		return vertInView && horInView;
	},*/

	passiveSupport() {
		return PASSIVE_SUPPORTED;
	},
	hasPassiveEvents: PASSIVE_SUPPORTED,
	testHrefIsActual: testHrefIsActual,
    preloadImages: preloadImages,
    preloadImagesSequential: preloadImagesSequential,
    detectConnectionType: detectConnectionType,
    detectPerformanceBots: detectPerformanceBots,
	isElementInViewport: isElementInViewport
};

export default utils;
