/* CSS */
import "@js/parts/css";

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

// Imports
import LazyLoad from 'vanilla-lazyload';
import MicroModal from 'micromodal';
import reframe from 'reframe.js';

// Project utils
import env from '@js/utils/env';
import utils from '@js/utils/utils';

// Project modules
import PageScrollingManager from '@js/modules/PageScrollingManager';
import PageScrolling from '@js/modules/PageScrolling';
import Carousels from "@js/modules/Carousels";

window.APP =
    window.APP ||
    new (function APP() {
        // Utilities
        this.env = env;
        this.utils = utils;


        // DOM load
        document.addEventListener('DOMContentLoaded', e => {
            /**
             * Lazy load
             */
            let lazyLoadInstance = new LazyLoad({
                threshold: 400
            });

            /**
             * Reframe
             */
            reframe('.reframe');

            /**
             * Carousels
             */
            Carousels.init();

            /**
             * Modal
             */
            MicroModal.init({
                openClass: '_open', // Custom class to be applied when modal is open
                openTrigger: 'data-modal-open', // Custom data attribute to open modal
                closeTrigger: 'data-modal-close', // Custom data attribute to close modal
                onShow: modal => {
                    // This is fired when the modal is opening.
                    // The function receives the modal object as the first parameter and the trigger element as second parameter.
                    const modalId = modal.getAttribute('id');

                    // Disable smooth scrolling
                    PageScrolling.lock('.modal');

                    // Check if modal is video
                    if (modalId === 'modal-video') {
                        // Play video
                        const videoEls = modal.querySelectorAll('video');
                        videoEls.forEach((video) => {
                            video.play();
                        });
                    }

                },
                onClose: modal => {
                    //This is fired when the modal is closing.
                    // The function receives the modal object as the first parameter and the trigger element as second parameter.
                    const modalId = modal.getAttribute('id');

                    // Enable smooth scrolling
                    PageScrolling.unlock('.modal');

                    if (modalId === 'modal-video') {
                        // Stop video
                        const videoEls = modal.querySelectorAll('video');
                        videoEls.forEach((video) => {
                            video.pause();
                        });
                    }
                }
            });
        });

        // Window Load
        window.addEventListener('load', e => {
            PageScrollingManager.update(true);
        });

    })();


